import { API } from "aws-amplify";
import { unixToLocal, unixToLocalTime } from "./time.js";
import { durationOfCall } from "../utils/helper functions/files_data_helpers.js";
import {
  IN_PROGRESS,
  COMPLETE,
  FAILED,
  CALL_TIMEOUT_SECONDS,
} from "./constants.js";

export const subscribeUpdateCall = (projectID, callID, setCall) => {
  const subscription = API.graphql({
    query: `
    subscription OnCallByProjectId($projectID: ID!) {
        onCallByProjectId(projectID: $projectID) {
          id
          projectID
          audioFileName
          contains
          duration
          date
          transcriptionReady
          transcription
          classifierContainsEvidence
          classifierSeverityScore
          classifierTags
          classifierSummary
          classifierHasBeenReviewed
          classifierResultWasAccepted
          project {
            title
            id
          }
        }
      }`,
    variables: {
      projectID: projectID,
    },
  }).subscribe({
    next: ({ value }) => {
      const call = value.data.onCallByProjectId;
      if (call.id === callID) {
        call.transcription = JSON.parse(call.transcription);
        call.time = unixToLocalTime(call.date, false);
        call.date = unixToLocal(call.date, true);
        call.durationString = durationOfCall(call);
        setCall(call);
        subscription.unsubscribe();
      }
    },
  });
  return subscription;
};

export const subscribeUpdateProject = (projectID, setNewCall) => {
  const subscription = API.graphql({
    query: `
    subscription OnCallByProjectId($projectID: ID!) {
        onCallByProjectId(projectID: $projectID) {
          id
          projectID
          transcriptionReady
          duration
          contains
          errorMessage
          classifierContainsEvidence
          classifierSeverityScore
          classifierTags
          classifierSummary
          classifierHasBeenReviewed
          classifierResultWasAccepted
        }
      }`,
    variables: {
      projectID: projectID,
    },
  }).subscribe({
    next: ({ value }) => {
      let call = value.data.onCallByProjectId;
      if (!call.transcriptionReady) {
        if (call.errorMessage != null) {
          call.status = call.errorMessage;
        } else {
          const timeSinceCreation =
            Math.floor(Date.now() / 1000) -
            Math.floor(new Date(call.createdAt).getTime() / 1000);
          if (timeSinceCreation > CALL_TIMEOUT_SECONDS) {
            call.status = FAILED;
          } else {
            call.status = IN_PROGRESS;
          }
        }
      } else {
        call.status = COMPLETE;
      }
      setNewCall(call);
    },
  });
  return subscription;
};

export const subscribeUpdateUser = (
  userID,
  setSecondsAvailableToTranscribe
) => {
  const subscription = API.graphql({
    query: `
    subscription onUpdateUser($id: String) {
      onUpdateUser(id: $id) {
          id
          secondsAvailableToTranscribe
        }
      }`,
    variables: {
      id: userID,
    },
  }).subscribe({
    next: ({ value }) => {
      console.log(value);
      const user = value.data.onUpdateUser;
      if (user) {
        setSecondsAvailableToTranscribe(user.secondsAvailableToTranscribe);
      }
    },
  });
  return subscription;
};
