/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      secondsAvailableToTranscribe
      showIntroTutorial
      createdAt
      updatedAt
      projects {
        items {
          id
          title
          type
          date
          userID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      secondsAvailableToTranscribe
      showIntroTutorial
      createdAt
      updatedAt
      projects {
        items {
          id
          title
          type
          date
          userID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      secondsAvailableToTranscribe
      showIntroTutorial
      createdAt
      updatedAt
      projects {
        items {
          id
          title
          type
          date
          userID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      title
      type
      date
      userID
      createdAt
      updatedAt
      user {
        id
        secondsAvailableToTranscribe
        showIntroTutorial
        createdAt
        updatedAt
        projects {
          nextToken
        }
      }
      owner
      calls {
        items {
          id
          projectID
          audioFileName
          transcription
          transcriptionReady
          errorMessage
          userMarkedHasEvidence
          classifierContainsEvidence
          classifierSeverityScore
          classifierTags
          classifierSummary
          classifierHasBeenReviewed
          classifierResultWasAccepted
          date
          duration
          contains
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      title
      type
      date
      userID
      createdAt
      updatedAt
      user {
        id
        secondsAvailableToTranscribe
        showIntroTutorial
        createdAt
        updatedAt
        projects {
          nextToken
        }
      }
      owner
      calls {
        items {
          id
          projectID
          audioFileName
          transcription
          transcriptionReady
          errorMessage
          userMarkedHasEvidence
          classifierContainsEvidence
          classifierSeverityScore
          classifierTags
          classifierSummary
          classifierHasBeenReviewed
          classifierResultWasAccepted
          date
          duration
          contains
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      title
      type
      date
      userID
      createdAt
      updatedAt
      user {
        id
        secondsAvailableToTranscribe
        showIntroTutorial
        createdAt
        updatedAt
        projects {
          nextToken
        }
      }
      owner
      calls {
        items {
          id
          projectID
          audioFileName
          transcription
          transcriptionReady
          errorMessage
          userMarkedHasEvidence
          classifierContainsEvidence
          classifierSeverityScore
          classifierTags
          classifierSummary
          classifierHasBeenReviewed
          classifierResultWasAccepted
          date
          duration
          contains
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
    }
  }
`;
export const createCall = /* GraphQL */ `
  mutation CreateCall(
    $input: CreateCallInput!
    $condition: ModelCallConditionInput
  ) {
    createCall(input: $input, condition: $condition) {
      id
      projectID
      audioFileName
      transcription
      transcriptionReady
      errorMessage
      userMarkedHasEvidence
      classifierContainsEvidence
      classifierSeverityScore
      classifierTags
      classifierSummary
      classifierHasBeenReviewed
      classifierResultWasAccepted
      date
      duration
      contains
      createdAt
      updatedAt
      project {
        id
        title
        type
        date
        userID
        createdAt
        updatedAt
        user {
          id
          secondsAvailableToTranscribe
          showIntroTutorial
          createdAt
          updatedAt
        }
        owner
        calls {
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateCall = /* GraphQL */ `
  mutation UpdateCall(
    $input: UpdateCallInput!
    $condition: ModelCallConditionInput
  ) {
    updateCall(input: $input, condition: $condition) {
      id
      projectID
      audioFileName
      transcription
      transcriptionReady
      errorMessage
      userMarkedHasEvidence
      classifierContainsEvidence
      classifierSeverityScore
      classifierTags
      classifierSummary
      classifierHasBeenReviewed
      classifierResultWasAccepted
      date
      duration
      contains
      createdAt
      updatedAt
      project {
        id
        title
        type
        date
        userID
        createdAt
        updatedAt
        user {
          id
          secondsAvailableToTranscribe
          showIntroTutorial
          createdAt
          updatedAt
        }
        owner
        calls {
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteCall = /* GraphQL */ `
  mutation DeleteCall(
    $input: DeleteCallInput!
    $condition: ModelCallConditionInput
  ) {
    deleteCall(input: $input, condition: $condition) {
      id
      projectID
      audioFileName
      transcription
      transcriptionReady
      errorMessage
      userMarkedHasEvidence
      classifierContainsEvidence
      classifierSeverityScore
      classifierTags
      classifierSummary
      classifierHasBeenReviewed
      classifierResultWasAccepted
      date
      duration
      contains
      createdAt
      updatedAt
      project {
        id
        title
        type
        date
        userID
        createdAt
        updatedAt
        user {
          id
          secondsAvailableToTranscribe
          showIntroTutorial
          createdAt
          updatedAt
        }
        owner
        calls {
          nextToken
        }
      }
      owner
    }
  }
`;
export const createUsageRecord = /* GraphQL */ `
  mutation CreateUsageRecord(
    $input: CreateUsageRecordInput!
    $condition: ModelUsageRecordConditionInput
  ) {
    createUsageRecord(input: $input, condition: $condition) {
      id
      userID
      date
      action
      duration
      callID
      availableTimeAfterTransaction
      createdAt
      updatedAt
      user {
        id
        secondsAvailableToTranscribe
        showIntroTutorial
        createdAt
        updatedAt
        projects {
          nextToken
        }
      }
    }
  }
`;
export const updateUsageRecord = /* GraphQL */ `
  mutation UpdateUsageRecord(
    $input: UpdateUsageRecordInput!
    $condition: ModelUsageRecordConditionInput
  ) {
    updateUsageRecord(input: $input, condition: $condition) {
      id
      userID
      date
      action
      duration
      callID
      availableTimeAfterTransaction
      createdAt
      updatedAt
      user {
        id
        secondsAvailableToTranscribe
        showIntroTutorial
        createdAt
        updatedAt
        projects {
          nextToken
        }
      }
    }
  }
`;
export const deleteUsageRecord = /* GraphQL */ `
  mutation DeleteUsageRecord(
    $input: DeleteUsageRecordInput!
    $condition: ModelUsageRecordConditionInput
  ) {
    deleteUsageRecord(input: $input, condition: $condition) {
      id
      userID
      date
      action
      duration
      callID
      availableTimeAfterTransaction
      createdAt
      updatedAt
      user {
        id
        secondsAvailableToTranscribe
        showIntroTutorial
        createdAt
        updatedAt
        projects {
          nextToken
        }
      }
    }
  }
`;
