// components/ClassifierSummary.jsx

/**
 * ClassifierSummary.jsx
 *
 * This component displays the classifier's summary and evidence for a call.
 * It includes the severity score, evidence tags, summary text, and action buttons.
 * Severity level 5 (Critical Severity) is distinguished with a special border and background color.
 * The design is consistent with the website's theme and uses MUI components.
 */

import React, { useState } from "react";
import styled from "styled-components";
import { classifierAccepted, classifierRejected } from "../utils/mutations";

// MUI Components
import {
  Alert,
  AlertTitle,
  Chip,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Styled Components
const SummaryWrapper = styled.div`
  margin-bottom: 20px;
`;

// Custom styling for the Alert component when severity is level 5 (Critical)
const CriticalAlert = styled(Alert)`
  border: 2px solid red;
  background-color: #ffebee; /* Light red background */
`;

const TagsWrapper = styled(Box)`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const ActionButtonsWrapper = styled(Box)`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const HiddenSummary = styled.div`
  text-align: center;
  color: grey;
  font-style: italic;
`;

const ClassifierSummary = ({ currentCall, setCurrentCall }) => {
  const [hidden, setHidden] = useState(
    //Set hidden if user has marked not evidence
    currentCall.classifierHasBeenReviewed &&
      !currentCall.classifierResultWasAccepted
  );

  // Determine severity details
  const getSeverityDetails = () => {
    const score = currentCall.classifierSeverityScore;
    if (score === null || score === undefined) {
      return { label: "Unknown", severity: "info" };
    } else if (score === 0) {
      return { label: "No Relevance", severity: "success" };
    } else if (score === 1 || score === 2) {
      return { label: "Low Severity", severity: "info" };
    } else if (score === 3) {
      return { label: "Medium Severity", severity: "warning" };
    } else if (score === 4) {
      return { label: "High Severity", severity: "error" };
    } else if (score === 5) {
      return { label: "Critical Severity", severity: "critical" };
    }
  };

  const severityDetails = getSeverityDetails();

  // Review status
  const needsReview =
    currentCall.classifierContainsEvidence &&
    !currentCall.classifierHasBeenReviewed;
  const classifierAcceptedByUser =
    currentCall.classifierContainsEvidence &&
    currentCall.classifierHasBeenReviewed &&
    currentCall.classifierResultWasAccepted;
  const classifierRejectedByUser =
    currentCall.classifierContainsEvidence &&
    currentCall.classifierHasBeenReviewed &&
    !currentCall.classifierResultWasAccepted;
  const callHasNoEvidence = !currentCall.classifierContainsEvidence;

  // Handlers
  const handleAccept = async () => {
    const id = await classifierAccepted(currentCall.id);
    if (id === currentCall.id) {
      setCurrentCall((call) => ({
        ...call,
        classifierHasBeenReviewed: true,
        classifierResultWasAccepted: true,
      }));
    } else {
      console.error("Could not update database to accept classifier");
    }
  };

  const handleReject = async () => {
    const id = await classifierRejected(currentCall.id);
    if (id === currentCall.id) {
      setCurrentCall((call) => ({
        ...call,
        classifierHasBeenReviewed: true,
        classifierResultWasAccepted: false,
      }));
      setHidden(true);
    } else {
      console.error("Could not update database to reject classifier");
    }
  };

  const handleUnhide = () => {
    setHidden(false);
  };

  if (hidden) {
    return (
      <SummaryWrapper>
        <HiddenSummary>Summary Hidden</HiddenSummary>
        <Box textAlign="center" marginTop={2}>
          <Button variant="outlined" onClick={handleUnhide}>
            Show Summary
          </Button>
        </Box>
      </SummaryWrapper>
    );
  }

  // Decide which Alert component to use based on severity
  const AlertComponent =
    severityDetails.severity === "critical" ? CriticalAlert : Alert;

  return (
    <SummaryWrapper>
      <AlertComponent
        severity={
          severityDetails.severity === "critical"
            ? "error"
            : severityDetails.severity
        }
      >
        <AlertTitle>{severityDetails.label} - Call Summary</AlertTitle>

        {/* Classifier Summary Text */}
        <Typography variant="body1" gutterBottom>
          {currentCall.classifierSummary}
        </Typography>

        {/* Evidence Tags */}
        {currentCall.classifierTags &&
          currentCall.classifierTags.length > 0 && (
            <TagsWrapper>
              {currentCall.classifierTags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </TagsWrapper>
          )}

        {/* Action Buttons */}
        <ActionButtonsWrapper>
          {needsReview && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAccept}
              >
                Accept as Evidence
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleReject}
              >
                Mark as Not Relevant
              </Button>
            </>
          )}
          {classifierAcceptedByUser && (
            <Button variant="outlined" color="secondary" onClick={handleReject}>
              Mark as Not Relevant
            </Button>
          )}
          {classifierRejectedByUser && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAccept}
              >
                Accept as Evidence
              </Button>
              <Button variant="text" onClick={() => setHidden(true)}>
                Hide Summary
              </Button>
            </>
          )}
          {callHasNoEvidence && (
            <Button variant="text" onClick={() => setHidden(true)}>
              Hide Summary
            </Button>
          )}
        </ActionButtonsWrapper>
      </AlertComponent>
    </SummaryWrapper>
  );
};

export default ClassifierSummary;
