/**
 * WarningErrorSnackbars.jsx
 *
 * This component renders up to three different snackbars:
 *  1) An error snackbar (red) if "errorMessage" is set,
 *  2) A warning snackbar (orange) if "warningMessage" is set,
 *  3) An info snackbar (blue) if "infoMessage" is set.
 *
 * All three snackbars are independent. Only the ones with a truthy
 * message value will appear. Each has a separate close handler so
 * existing usage in other files remains unaffected.
 */

import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

/**
 * @param {string|boolean} errorMessage      - The error text to display. If falsy, no error snackbar.
 * @param {function}       setErrorMessage   - Sets the error message state to close or update.
 * @param {string|boolean} warningMessage    - The warning text to display. If falsy, no warning snackbar.
 * @param {function}       setWarningMessage - Sets the warning message state to close or update.
 * @param {string|boolean} infoMessage       - The info text to display. If falsy, no info snackbar.
 * @param {function}       setInfoMessage    - Sets the info message state to close or update.
 */
const WarningErrorSnackbars = ({
  errorMessage = false,
  setErrorMessage = () => {},
  warningMessage = false,
  setWarningMessage = () => {},
  infoMessage = false,
  setInfoMessage = () => {},
}) => {
  /**
   * Creates a filled-variant Material UI Alert used inside the Snackbar.
   */
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /**
   * Closes the error snackbar when the user clicks the close button or
   * when autoHideDuration completes.
   */
  const handleCloseError = (event, reason) => {
    if (reason !== "clickaway") {
      setErrorMessage(false);
    }
  };

  /**
   * Closes the warning snackbar when the user clicks the close button or
   * when autoHideDuration completes.
   */
  const handleCloseWarning = (event, reason) => {
    if (reason !== "clickaway") {
      setWarningMessage(false);
    }
  };

  /**
   * Closes the info snackbar when the user clicks the close button or
   * when autoHideDuration completes.
   */
  const handleCloseInfo = (event, reason) => {
    if (reason !== "clickaway") {
      setInfoMessage(false);
    }
  };

  return (
    <>
      {/* Error Snackbar */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={10000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="error"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseError}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* Warning Snackbar */}
      <Snackbar
        open={Boolean(warningMessage)}
        autoHideDuration={10000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseWarning}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {warningMessage}
        </Alert>
      </Snackbar>

      {/* Info Snackbar */}
      <Snackbar
        open={Boolean(infoMessage)}
        autoHideDuration={10000}
        onClose={handleCloseInfo}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="info"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseInfo}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {infoMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default WarningErrorSnackbars;
