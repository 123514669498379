// CasePage.jsx

/**
 * CasePage.jsx
 *
 * This file represents the page for a single case, displaying all the calls associated with it.
 * Users can:
 *  - View key metrics (Date Made, Type, Call Count, etc.) in a Jumbotron.
 *  - Perform searches for calls by filename using an Autocomplete text field.
 *  - Select calls (using DataGrid checkboxes) to delete or download (PDF/Word).
 *  - Download calls either fully or in a redacted version, if available.
 *  - Observe the transcription status of each call (COMPLETE, IN_PROGRESS, FAILED).
 *  - Inline-edit both the Case Name (title) and the Case Type for this project.
 *
 * In addition, the component handles:
 *  - Loading state while retrieving data from AWS (Amplify).
 *  - Subscriptions to transcriptions in-progress (via subscribeUpdateProject).
 *  - Warnings and errors (e.g., if no calls are selected, or if there are issues downloading).
 *  - Keeping local state in sync with the server whenever a user edits or deletes data.
 */

import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

// MUI and third-party libraries
import {
  TextField,
  FormControlLabel,
  Tooltip,
  IconButton,
  Backdrop,
  Switch,
  Autocomplete,
  Badge,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";

// React Router
import { useHistory } from "react-router-dom";

// Internal Components
import MainJumbotron from "../components/MainJumbotron.jsx";
import StyledButton from "../components/StyledButton.jsx";
import KeyTermsModal from "../components/KeyTermsModal.jsx";
import TemplateModal from "../components/TemplateModal.jsx";
import WarningErrorSnackbars from "../components/WarningErrorSnackbar.jsx";
import Loader from "../components/Loader.jsx";
import SEO from "../components/SEO.jsx";

// AWS Queries/Mutations/Subscriptions
import { getProject, getCall } from "../utils/queries.js";
import { deleteCalls } from "../utils/mutations.js";
import { subscribeUpdateProject } from "../utils/subscriptions.js";
import { API } from "aws-amplify";
import { updateProject } from "../graphql/mutations"; // Auto-generated by Amplify

// Constants and Helpers
import { COMPLETE, IN_PROGRESS, FAILED } from "../utils/constants.js";
import { secsToString } from "../utils/helper functions/files_data_helpers.js";
import { UserContext } from "../utils/context.js";
import { getCookieConsentValue } from "react-cookie-consent";
import PDFTranscription from "../components/PDFTranscription.jsx";
import WordTranscription from "../components/WordTranscription.jsx";
import { pdf } from "@react-pdf/renderer";
import JSZip from "jszip";
import { saveAs } from "file-saver";

/**
 * ------------------
 * Styled Components
 * ------------------
 */

// Container that holds the entire page content (header, table, etc.).
const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

// Main content area below the Jumbotron.
const MainContentWrapper = styled.div`
  margin: 0px 60px;
`;

// Title + Buttons row at the top of the main content area.
const MainContentHeading = styled.div`
  padding: 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// DataGrid container.
const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  .MuiDataGrid-root .MuiDataGrid-row {
    cursor: pointer;
  }
`;

// Row of buttons above the DataGrid (Delete, Download PDF, Download Word, etc.).
const TableButtonsWrapper = styled.div`
  border-top: 1px solid rgb(74, 74, 74);
  width: 100%;
  display: flex;
  align-items: center;
  height: 41px;
  padding-left: 6px;
`;

/**
 * Styled version of the MUI TextField for consistency with your existing designs.
 */
const StyledTextField = styled(TextField)`
  && {
    margin: 8px 0;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
  }

  .MuiOutlinedInput-root {
    font-size: 0.9rem;
  }
`;

/**
 * ----------------------------------------------------------------------------
 * DataGrid Column Definitions
 * ----------------------------------------------------------------------------
 * Each column object defines how a field in the 'rows' data is displayed,
 * including width, alignment, and custom cell renderers.
 */
const columns = [
  {
    field: "audioFileName",
    headerName: "File Name",
    width: 300,
  },
  {
    field: "dateUnixTime",
    headerName: "Date",
    width: 200,
    type: "dateTime",
    valueGetter: ({ value }) => new Date(value * 1000), // Convert UnixTime to JS Date object
  },
  {
    field: "duration",
    headerName: "Duration",
    width: 200,
    type: "number",
    headerAlign: "left",
    align: "left",
    // secsToString is a helper function that converts total seconds to a string "HH:MM:SS".
    valueFormatter: ({ value }) => secsToString(value),
  },
  {
    field: "status",
    headerName: "Transcript Status",
    width: 150,
  },
  {
    field: "contains",
    headerName: "Contains",
    width: 469,
    // Our code stores the "contains" array as strings, so we join them with a delimiter and then split them for display.
    valueGetter: ({ value }) => value.join("___"),
    renderCell: (params) => {
      // RenderCellExpand is a custom component that expands text on hover if it is truncated.
      const RenderCellExpand =
        require("../components/RenderCellExpand.jsx").default;
      return (
        <RenderCellExpand
          value={params.value.split("___")}
          width={params.colDef.width}
        />
      );
    },
  },
];

/**
 * ----------------------------------------------------------------------------
 * CasePage Component
 * ----------------------------------------------------------------------------
 * Displays a single "Case" (Project), along with a list of calls,
 * providing functionality to edit, delete, download, and search calls.
 */
const CasePage = ({ match }) => {
  /**
   * ----------------------
   * Cookie Consent Check
   * ----------------------
   * We check if the user consents to storing page size in localStorage.
   */
  const consent = getCookieConsentValue("WireTapCookieConsent");

  /**
   * ------------------------------------------------------------------
   * Inline Edit States for Case Title & Case Type
   * ------------------------------------------------------------------
   *  - editingTitle (bool) toggles inline editing of the case name
   *  - tempTitle holds the current user-typed title
   *  - editingType (bool) toggles inline editing of the case type
   *  - tempType holds the current user-typed type
   */
  const [editingTitle, setEditingTitle] = useState(false);
  const [tempTitle, setTempTitle] = useState("");
  const [editingType, setEditingType] = useState(false);
  const [tempType, setTempType] = useState("");

  /**
   * ------------------------------------------------------------------
   * Other Local State Variables
   * ------------------------------------------------------------------
   */
  // DataGrid page size (rows per page).
  const [pageSize, setPageSize] = useState(() => {
    if (consent === "false") {
      return 50; // If user doesn't allow cookies, just default to 50
    } else {
      // If consent is true, attempt to load from localStorage or else default to 50
      return localStorage.getItem("CallsPageSize") || 50;
    }
  });

  // Holds the currentCase (AKA Project) info: title, dateMade, type, callCount, etc.
  const [currentCase, setCurrentCase] = useState({
    id: "",
    title: "",
    dateMade: "",
    type: "",
    callCount: "",
    totalDuration: "",
    status: "",
    calls: [],
  });

  // For the "Evidence Found" button's numeric badge
  const [evidenceCount, setEvidenceCount] = useState(0);

  // Autocomplete search input
  const [inputValue, setInputValue] = useState("");

  // The rows displayed in the DataGrid (subset of calls, possibly filtered by search)
  const [rows, setRows] = useState([]);

  // Indicates if the page is still loading data from AWS
  const [loading, setLoading] = useState(true);

  // Used to show a loading backdrop if the user is deleting or downloading calls
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  // Whether the "Find Key Terms" modal is open
  const [searchKeyTerms, setSearchKeyTerms] = useState(false);

  // Tracks active subscriptions to the transcribe process
  const [subscription, setSubscription] = useState(undefined);

  // Contains IDs of calls that are selected in the DataGrid
  const [selectedRows, setSelectedRows] = useState([]);

  // Controls opening the Delete Confirmation modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // Sort model for the DataGrid
  const [sortModel, setSortModel] = useState([
    {
      field: "contains",
      sort: "desc",
    },
  ]);

  // Variables for error & warning snackbars
  const [ErrorSnackbar, setErrorSnackbar] = useState(false);
  const [WarnSnackbar, setWarnSnackbar] = useState(false);
  const [InfoSnackbar, setInfoSnackbar] = useState(false);

  // Whether we should download a "redacted" version of the transcripts
  const [downloadRedacted, setDownloadRedacted] = useState(false);

  // React Router and user context
  const history = useHistory();
  const user = useContext(UserContext);

  /**
   * ------------------------------------------------------------------
   * updateCaseFields
   * ------------------------------------------------------------------
   * Helper function that calls the Amplify API to run the updateProject
   * mutation. This will update the given project's title and/or type.
   */
  const updateCaseFields = async (caseID, newTitle = null, newType = null) => {
    // 'input' is the object we send to the 'updateProject' mutation
    const input = { id: caseID };
    // Only include "title" or "type" if they are not null
    if (newTitle !== null) input.title = newTitle;
    if (newType !== null) input.type = newType;

    // Call Amplify's API.graphql method
    const response = await API.graphql({
      query: updateProject,
      variables: { input },
    });

    // The updated project is returned under response.data.updateProject
    return response.data.updateProject;
  };

  /**
   * ------------------------------------------------------------------
   * Fetch the project from AWS on initial load (and if user changes)
   * ------------------------------------------------------------------
   * This sets the 'currentCase' state once the data is retrieved.
   */
  useEffect(() => {
    const getCase = async () => {
      const caseID = match.params.id;
      const projectInfo = await getProject(caseID, false);
      if (typeof projectInfo === "string") {
        // If it's a string, presumably it's an error message
        console.error(`Failed to receive project: ${projectInfo}`);
        history.push("/unauthorized");
        return;
      }
      setCurrentCase(projectInfo);
    };

    if (user) {
      getCase();
    }
  }, [match.params.id, user, history]);

  /**
   * ------------------------------------------------------------------
   * Subscribe to project updates if there are calls still transcribing
   * ------------------------------------------------------------------
   * Once the transcription is finished for all calls, we can unsubscribe.
   */
  useEffect(() => {
    const caseID = match.params.id;

    // Callback used by subscription to update an individual call's info
    const setNewCall = async (call) => {
      const callIndex = currentCase.calls.findIndex(
        (element) => element.id === call.id
      );
      // If the call is not found, reload the entire project
      if (callIndex === -1) {
        setCurrentCase(await getProject(caseID, false));
      } else {
        // Merge the updated call fields into the existing call
        let newCalls = [...currentCase.calls];
        newCalls[callIndex] = { ...newCalls[callIndex], ...call };

        // Determine if the entire project is still in progress or all complete
        let projectStatus = COMPLETE;
        for (const tempCall of newCalls) {
          if (tempCall.status === IN_PROGRESS) {
            projectStatus = IN_PROGRESS;
          }
        }

        // Update state with the new calls array and possibly updated status
        setCurrentCase({
          ...currentCase,
          calls: newCalls,
          status: projectStatus,
        });
      }
    };

    // If at least one call is not done transcribing, we subscribe
    if (currentCase.calls.some((call) => !call.transcriptionReady)) {
      if (!subscription) {
        const sub = subscribeUpdateProject(caseID, setNewCall);
        setSubscription(sub);
      }
    }
    // If all calls are done, unsubscribe
    else if (subscription) {
      subscription.unsubscribe();
    }

    // Cleanup subscription on unmount
    return () => {
      if (subscription) {
        subscription.unsubscribe();
        setSubscription(undefined);
      }
    };
  }, [currentCase, match.params.id, subscription]);

  /**
   * ------------------------------------------------------------------
   * Filter the calls by search input & prepare them for DataGrid
   * ------------------------------------------------------------------
   * If a call is COMPLETE, we append "✓" to show the user that it is done,
   * or "..." if it's still in progress.
   */
  useEffect(() => {
    if (currentCase.title) {
      let rowsFiltered = [];
      currentCase.calls.forEach((item) => {
        if (
          item.audioFileName.toLowerCase().includes(inputValue.toLowerCase())
        ) {
          // Adjust item.status for UI display
          if (item.status === COMPLETE) {
            item.status = `${COMPLETE} ✓`;
          } else if (item.status === IN_PROGRESS) {
            item.status = `${IN_PROGRESS}...`;
          } else {
            // For FAILED or any other statuses
            item.status = `${item.status}`;
          }
          rowsFiltered.push(item);
        }
      });
      setRows(rowsFiltered);
      setLoading(false);
    }
  }, [currentCase.title, currentCase.calls, inputValue]);

  /**
   * ------------------------------------------------------------------
   * Update Evidence Count Badge
   * ------------------------------------------------------------------
   * This displays how many calls have 'classifierContainsEvidence'
   * but 'classifierHasBeenReviewed' is still false.
   */
  useEffect(() => {
    if (currentCase.calls.length > 0) {
      let count = 0;
      for (const call of currentCase.calls) {
        if (
          call.classifierContainsEvidence &&
          !call.classifierHasBeenReviewed
        ) {
          count++;
        }
      }
      setEvidenceCount(count);
    }
  }, [currentCase.calls]);

  /**
   * ------------------------------------------------------------------
   * Handle Inline Edit Save: Case Title
   * ------------------------------------------------------------------
   * Validates the new title is non-empty, calls updateCaseFields,
   * then updates local state & notifies user.
   */
  const handleSaveTitle = async () => {
    if (!tempTitle.trim()) {
      setErrorSnackbar("Case name cannot be empty.");
      return;
    }
    try {
      const updatedProject = await updateCaseFields(currentCase.id, tempTitle);
      // Update local state with new title
      setCurrentCase({ ...currentCase, title: updatedProject.title });
      setEditingTitle(false);
      setInfoSnackbar("Case name updated successfully");
    } catch (err) {
      console.error("Error updating case title:", err);
      setErrorSnackbar("Unable to save case name. Please try again.");
    }
  };

  /**
   * ------------------------------------------------------------------
   * Handle Inline Edit Save: Case Type
   * ------------------------------------------------------------------
   * Validates the new type is non-empty, calls updateCaseFields,
   * then updates local state & notifies user.
   */
  const handleSaveType = async () => {
    if (!tempType.trim()) {
      setErrorSnackbar("Case type cannot be empty.");
      return;
    }
    try {
      const updatedProject = await updateCaseFields(
        currentCase.id,
        null,
        tempType
      );
      // Update local state with new type
      setCurrentCase({ ...currentCase, type: updatedProject.type });
      setEditingType(false);
      setInfoSnackbar("Case type updated successfully");
    } catch (err) {
      console.error("Error updating case type:", err);
      setErrorSnackbar("Unable to save case type. Please try again.");
    }
  };

  /**
   * ------------------------------------------------------------------
   * Handle Download PDF
   * ------------------------------------------------------------------
   *  - Gathers selected call IDs, fetches their data, filters out invalid calls.
   *  - Creates a zip file of PDFs (each call's transcript).
   *  - Warns the user if some calls are incomplete or failed.
   */
  const handleDownloadPDF = async () => {
    setDownloadLoading(true);
    try {
      const zip = new JSZip();
      const filenameCounts = {}; // Track repeated filenames

      // Fetch call data for each selected row
      const callsDataPromises = selectedRows.map((callId) => getCall(callId));
      const callsData = await Promise.all(callsDataPromises);

      // Separate valid from invalid
      const validCallsData = callsData.filter(
        (call) => typeof call !== "string" && call.status === COMPLETE
      );
      const invalidCallsData = callsData.filter(
        (call) =>
          typeof call === "string" || (call.status && call.status !== COMPLETE)
      );

      // If no valid calls, show error & exit
      if (validCallsData.length === 0) {
        setErrorSnackbar(
          "No files were downloaded because the selected files are still being transcribed or have failed."
        );
        setDownloadLoading(false);
        return;
      }

      // Build PDFs and add to the zip
      for (const call of validCallsData) {
        const pdfDocument = (
          <PDFTranscription currentCall={call} redacted={downloadRedacted} />
        );
        // Generate Blob from PDF
        const asBlob = await pdf(pdfDocument).toBlob();

        // Handle repeated filenames by appending (1), (2), etc.
        let baseFileName = `${call.audioFileName.replace(/\.[^/.]+$/, "")}`;
        if (filenameCounts[baseFileName]) {
          baseFileName = `${baseFileName} (${filenameCounts[baseFileName]})`;
          filenameCounts[baseFileName]++;
        } else {
          filenameCounts[baseFileName] = 1;
        }
        zip.file(`${baseFileName}.pdf`, asBlob);
      }

      // Generate final zip blob and download
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${currentCase.title}_Transcriptions.zip`);

      // If some calls were invalid, build a warning
      if (invalidCallsData.length > 0) {
        const failedCalls = invalidCallsData.filter(
          (call) => call.status && call.status !== IN_PROGRESS
        );
        const inProgressCalls = invalidCallsData.filter(
          (call) => call.status && call.status === IN_PROGRESS
        );

        let warningMessage = "";
        if (failedCalls.length > 0 && inProgressCalls.length > 0) {
          warningMessage = `${failedCalls.length} file(s) failed, and ${inProgressCalls.length} file(s) are still being transcribed.`;
        } else if (failedCalls.length > 0) {
          warningMessage = `${failedCalls.length} file(s) failed.`;
        } else if (inProgressCalls.length > 0) {
          warningMessage = `${inProgressCalls.length} file(s) are still being transcribed.`;
        }
        setWarnSnackbar(warningMessage);
      }
    } catch (error) {
      console.error("Error downloading PDFs:", error);
      setErrorSnackbar("An error occurred while downloading PDFs.");
    }
    setDownloadLoading(false);
  };

  /**
   * ------------------------------------------------------------------
   * Handle Download Word
   * ------------------------------------------------------------------
   * Similar to handleDownloadPDF, but generates Word docs instead.
   */
  const handleDownloadWord = async () => {
    setDownloadLoading(true);
    try {
      const zip = new JSZip();
      const filenameCounts = {};

      // Fetch call data
      const callsDataPromises = selectedRows.map((callId) => getCall(callId));
      const callsData = await Promise.all(callsDataPromises);

      // Split valid vs invalid calls
      const validCallsData = callsData.filter(
        (call) => typeof call !== "string" && call.status === COMPLETE
      );
      const invalidCallsData = callsData.filter(
        (call) =>
          typeof call === "string" || (call.status && call.status !== COMPLETE)
      );

      // If no valid calls, error out
      if (validCallsData.length === 0) {
        setErrorSnackbar(
          "No files were downloaded because the selected files are still being transcribed or have failed."
        );
        setDownloadLoading(false);
        return;
      }

      // Build Word docs and add to the zip
      for (const call of validCallsData) {
        const blob = await WordTranscription(call, downloadRedacted, true);
        let baseFileName = `${call.audioFileName.replace(/\.[^/.]+$/, "")}`;
        if (filenameCounts[baseFileName]) {
          baseFileName = `${baseFileName} (${filenameCounts[baseFileName]})`;
          filenameCounts[baseFileName]++;
        } else {
          filenameCounts[baseFileName] = 1;
        }
        zip.file(`${baseFileName}.docx`, blob);
      }

      // Generate final zip blob and download
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${currentCase.title}_Transcriptions.zip`);

      // Warn if some calls were invalid
      if (invalidCallsData.length > 0) {
        const failedCalls = invalidCallsData.filter(
          (call) => call.status && call.status !== IN_PROGRESS
        );
        const inProgressCalls = invalidCallsData.filter(
          (call) => call.status && call.status === IN_PROGRESS
        );

        let warningMessage = "";
        if (failedCalls.length > 0 && inProgressCalls.length > 0) {
          warningMessage = `${failedCalls.length} file(s) failed, and ${inProgressCalls.length} file(s) are still being transcribed.`;
        } else if (failedCalls.length > 0) {
          warningMessage = `${failedCalls.length} file(s) failed.`;
        } else if (inProgressCalls.length > 0) {
          warningMessage = `${inProgressCalls.length} file(s) are still being transcribed.`;
        }
        setWarnSnackbar(warningMessage);
      }
    } catch (error) {
      console.error("Error downloading Word documents:", error);
      setErrorSnackbar("An error occurred while downloading Word documents.");
    }
    setDownloadLoading(false);
  };

  /**
   * ------------------------------------------------------------------
   * Dynamically build the "metrics" array for MainJumbotron
   * ------------------------------------------------------------------
   * Notice that for "Type," we either show an editable multiline field
   * (when editingType=true) or the static text plus an edit icon.
   */
  const metricsForJumbotron = [
    { header: "Date Made", body: currentCase.dateMade },
    { header: "Call Count", body: currentCase.callCount },
    { header: "Total Call Time", body: currentCase.totalDuration },
    { header: "Status", body: currentCase.status },
  ];

  /**
   * ------------------------------------------------------------------
   * Return (JSX)
   * ------------------------------------------------------------------
   */
  return (
    <Wrapper>
      {/* SEO metadata */}
      <SEO
        title="Case | WireTap"
        description="Review your audio files for a single case. Search calls, update case info, and more."
      />

      {/* Main Jumbotron, containing the case title & metrics */}
      <MainJumbotron
        title={
          editingTitle ? (
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <StyledTextField
                value={tempTitle}
                onChange={(e) => setTempTitle(e.target.value)}
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 50 }}
                style={{ width: "300px" }}
              />
              <StyledButton
                onClick={handleSaveTitle}
                color={"white"}
                style={{ background: "linear-gradient(#209BCF, #26B7F5)" }}
              >
                Save
              </StyledButton>
              <StyledButton
                onClick={() => {
                  setEditingTitle(false);
                  setTempTitle(currentCase.title);
                }}
                color={"#209BCF"}
                style={{
                  background: "linear-gradient(#FFFFFF, #E9E9E9)",
                }}
              >
                Cancel
              </StyledButton>
            </div>
          ) : (
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              {currentCase.title}
              <Tooltip title="Edit Case Name" arrow>
                <IconButton
                  size="small"
                  onClick={() => {
                    setTempTitle(currentCase.title);
                    setEditingTitle(true);
                  }}
                  style={{ color: "white", marginLeft: "8px" }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          )
        }
        subtitle={
          editingType ? (
            /* Editing state for Case Type */
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              {/* Single-line text field for case type */}
              <StyledTextField
                variant="outlined"
                size="small"
                value={tempType}
                onChange={(e) => setTempType(e.target.value)}
                inputProps={{ maxLength: 100 }}
                style={{ width: "300px" }}
              />
              <StyledButton
                onClick={handleSaveType}
                color={"white"}
                style={{ background: "linear-gradient(#209BCF, #26B7F5)" }}
              >
                Save
              </StyledButton>
              <StyledButton
                onClick={() => {
                  setEditingType(false);
                  setTempType(currentCase.type);
                }}
                color={"#209BCF"}
                style={{
                  background: "linear-gradient(#FFFFFF, #E9E9E9)",
                }}
              >
                Cancel
              </StyledButton>
            </div>
          ) : (
            /* Viewing state for Case Type with smaller edit icon */
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <span
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  marginRight: "6px",
                }}
              >
                Case Type:
              </span>
              <span style={{ fontSize: "0.8rem", marginRight: "6px" }}>
                {currentCase.type}
              </span>
              <Tooltip title="Edit Case Type" arrow>
                <IconButton
                  size="small"
                  onClick={() => {
                    setTempType(currentCase.type);
                    setEditingType(true);
                  }}
                  style={{ color: "white" }}
                >
                  {/* Sets a custom font size for the icon (16px is just an example) */}
                  <EditIcon sx={{ fontSize: 14 }} />
                </IconButton>
              </Tooltip>
            </div>
          )
        }
        metrics={metricsForJumbotron}
        breadcrumb={[
          { name: "My Cases", link: `/home` },
          { name: currentCase.title },
        ]}
      />

      {/* The Main Content Area (Search, Buttons, and DataGrid) */}
      <MainContentWrapper>
        <MainContentHeading>
          {/* ---------------------
              Autocomplete Search
             --------------------- */}
          <Autocomplete
            id="combo-box-demo"
            options={currentCase.calls}
            getOptionLabel={(option) => option.audioFileName}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search for call"
                variant="outlined"
              />
            )}
          />

          {/* ---------------------
              Action Buttons
             --------------------- */}
          <div>
            {/* Evidence Found button with a numeric badge */}
            <Badge
              badgeContent={evidenceCount}
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "red",
                },
              }}
            >
              <StyledButton
                color={"white"}
                style={{
                  background: "linear-gradient(#209BCF, #26B7F5)",
                }}
                onClick={() =>
                  history.push(`/case/${match.params.id}/evidence`)
                }
              >
                Evidence Found
              </StyledButton>
            </Badge>

            {/* Find Key Terms */}
            <StyledButton
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                marginLeft: "20px",
              }}
              onClick={() => setSearchKeyTerms(true)}
            >
              Find Key Terms
            </StyledButton>

            {/* + Add new calls */}
            <StyledButton
              color={"#209BCF"}
              style={{
                background: "linear-gradient(#FFFFFF, #E9E9E9)",
                marginLeft: "20px",
              }}
              onClick={() => history.push(`/case/${match.params.id}/addcalls`)}
            >
              + Add new calls
            </StyledButton>
          </div>
        </MainContentHeading>

        {/* ---------------------
            Table Buttons
           --------------------- */}
        <TableButtonsWrapper>
          {/* Delete Button */}
          <Tooltip title="Delete" arrow>
            <span>
              <IconButton
                aria-label="delete"
                onClick={() => setOpenDeleteModal(true)}
                disabled={selectedRows.length === 0}
              >
                <DeleteIcon
                  sx={{
                    color:
                      selectedRows.length === 0
                        ? "rgba(74, 74, 74, 0.5)"
                        : "rgb(74, 74, 74)",
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>

          {/* Download PDF Button */}
          <Tooltip title="Download PDFs" arrow>
            <span>
              <IconButton
                aria-label="download-pdf"
                onClick={handleDownloadPDF}
                disabled={selectedRows.length === 0}
              >
                <PictureAsPdfIcon
                  sx={{
                    color:
                      selectedRows.length === 0
                        ? "rgba(74, 74, 74, 0.5)"
                        : "rgb(74, 74, 74)",
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>

          {/* Download Word Button */}
          <Tooltip title="Download Word Docs" arrow>
            <span>
              <IconButton
                aria-label="download-word"
                onClick={handleDownloadWord}
                disabled={selectedRows.length === 0}
              >
                <DescriptionIcon
                  sx={{
                    color:
                      selectedRows.length === 0
                        ? "rgba(74, 74, 74, 0.5)"
                        : "rgb(74, 74, 74)",
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>

          {/* Switch to toggle "Download Redacted" */}
          <FormControlLabel
            control={
              <Switch
                checked={downloadRedacted}
                onChange={(e) => setDownloadRedacted(e.target.checked)}
                color="primary"
              />
            }
            label="Download Redacted"
            style={{ marginLeft: "20px" }}
            componentsProps={{
              typography: {
                style: { fontSize: "0.875rem" },
              },
            }}
          />
        </TableButtonsWrapper>

        {/* ---------------------
            DataGrid (Calls)
           --------------------- */}
        <TableWrapper>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnFilter
            pageSize={parseInt(pageSize)}
            autoHeight
            disableColumnMenu
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            checkboxSelection
            onSelectionModelChange={(selectedRowIds) => {
              setSelectedRows(selectedRowIds);
            }}
            // Save new pageSize in localStorage if the user has consented
            onPageSizeChange={(newPageSize) => {
              if (consent !== "false") {
                localStorage.setItem("CallsPageSize", newPageSize);
              }
              setPageSize(newPageSize);
            }}
            // Navigate to the Call detail page on row click
            onRowClick={(gridRowParam) => {
              history.push(`/call/${gridRowParam.row.id}`);
            }}
            loading={loading}
          />
        </TableWrapper>
      </MainContentWrapper>

      {/* ---------------------
          Modals & Backdrops
         --------------------- */}
      {/* Key Terms Modal */}
      <KeyTermsModal
        open={searchKeyTerms}
        setOpen={setSearchKeyTerms}
        caseID={match.params.id}
      />

      {/* Delete Confirmation Modal */}
      <TemplateModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header={selectedRows.length > 1 ? "Delete Files" : "Delete File"}
        subHeader={`Deleting ${selectedRows.length} file${
          selectedRows.length > 1 ? "s" : ""
        }`}
        bodyElements={
          <span style={{ marginTop: "10px" }}>
            You are about to delete {selectedRows.length} file
            {selectedRows.length > 1 ? "s" : ""}. <b>This cannot be undone.</b>{" "}
            Please confirm you wish to delete{" "}
            {selectedRows.length > 1 ? "these files" : "this file"}.
          </span>
        }
        size="small"
        closeButton="Delete"
        closeCallback={async () => {
          // Perform deletion
          setDeleteLoading(true);
          await deleteCalls(selectedRows);
          // Reload project data
          const caseID = match.params.id;
          setCurrentCase(await getProject(caseID, false));
          setDeleteLoading(false);
        }}
      />

      {/* Backdrop for loading states when deleting or downloading */}
      <Backdrop
        open={deleteLoading || downloadLoading}
        style={{ zIndex: 1400 }}
      >
        <Loader width={40} height={40} fill="white" />
      </Backdrop>

      {/* Warning & Error Snackbars */}
      <WarningErrorSnackbars
        errorMessage={ErrorSnackbar}
        setErrorMessage={setErrorSnackbar}
        warningMessage={WarnSnackbar}
        setWarningMessage={setWarnSnackbar}
        infoMessage={InfoSnackbar}
        setInfoMessage={setInfoSnackbar}
      />
    </Wrapper>
  );
};

export default CasePage;
