// CallPage.jsx

/**
 * CallPage.jsx
 *
 * This file represents the main page for displaying a single call's transcript.
 * Users can:
 *  - View and edit the call's name (audioFileName) inline.
 *  - See metrics (date, duration, etc.) in the Jumbotron.
 *  - Read or interact with the transcript.
 *  - Download the transcript as PDF or Word.
 *  - Manage speakers in the transcript.
 *  - Observe whether the call is still transcribing or has failed.
 *
 * It fetches the call data from AWS, manages local state, handles updates,
 * and orchestrates the rendering of child components (Transcript, SpeakerList, etc.).
 */

import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";

// Internal Components
import MainJumbotron from "../components/MainJumbotron.jsx";
import Transcript from "../components/Transcript.jsx";
import SpeakerList from "../components/SpeakerList.jsx";
import PDFDownloadButton from "../components/PDFDownloadButton.jsx";
import WordTranscription from "../components/WordTranscription.jsx";
import LoadingScreen from "../components/LoadingScreen.jsx";
import WarningErrorSnackbars from "../components/WarningErrorSnackbar.jsx";
import StyledButton from "../components/StyledButton.jsx";

// MUI and Other Libraries
import { TextField, Tooltip, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

// AWS Queries/Mutations/Subscriptions
import { getCall } from "../utils/queries.js";
import { updateTranscription } from "../utils/mutations.js";
import { subscribeUpdateCall } from "../utils/subscriptions.js";
import { API } from "aws-amplify";
import { updateCall } from "../graphql/mutations"; // For updating the call name

// Context, Constants, Helpers
import { UserContext } from "../utils/context.js";
import { IN_PROGRESS, COMPLETE, TIMEOUT } from "../utils/constants.js";
import { getKeyTermsOnlyJSX } from "../utils/helper functions/keyterms_contains_helper.js";
import { KEY_TERMS_BOLD_DELIMINATOR } from "../utils/constants.js";

// SEO
import SEO from "../components/SEO.jsx";

/**
 * ------------------
 * Styled Components
 * ------------------
 */

// Main container that holds the entire page layout.
const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

// The region that contains the Transcript on the left and the right-side panel.
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

// The right side of the page, housing Speaker List, Downloads, etc.
const RightSideWrapper = styled.div`
  width: 25%;
  border-left: 2px solid grey;
  min-width: 290px;
`;

// Makes the right side content "sticky" so it scrolls in place with the page.
const ContentWrapper = styled.div`
  position: sticky;
  top: 20px;
`;

// A simple box with a light-grey border and rounded corners for grouping content.
const BorderedBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid lightgrey;
  border-radius: 20px;
  margin: 20px;
  padding: 10px;
`;

// A specialized version of BorderedBox that doesn't show a border.
const ButtonsWrapper = styled(BorderedBox)`
  border: none;
`;

// Heading text used within each BorderedBox.
const BoxHeading = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.heading};
  margin-bottom: 10px;
`;

// Each evidence line or key term is displayed in this styled div.
const EvidenceLine = styled.div`
  font-size: 1rem;
`;

// Provides a larger text for general info, used in the "Call Failed" block.
const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
`;

// A styled link that can be used in text paragraphs.
const StyledLink = styled(Link)`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
  text-decoration: none;
  font-size: 1.4rem;
`;

// Wrapper for failed calls, allowing us to present a clear error message.
const FailedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.textGrey};
  font-size: 1.9rem;
`;

/**
 * Styled version of the MUI TextField for consistency with your existing designs.
 */
const StyledTextField = styled(TextField)`
  && {
    margin: 8px 0;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
  }

  .MuiOutlinedInput-root {
    font-size: 0.9rem;
  }
`;

/**
 * CallPage Component
 *
 * Main container for displaying or editing a single call's info & transcript.
 * The user can rename the call, see a transcript, and optionally download it.
 */
const CallPage = ({ match }) => {
  /**
   * ------------------------------------------------------------------
   * Local State Variables
   * ------------------------------------------------------------------
   */
  // The current call object fetched from AWS.
  const [currentCall, setCurrentCall] = useState(undefined);

  // The set of unique speaker identifiers extracted from the transcript.
  const [speakers, setSpeakers] = useState(undefined);

  // For renaming speakers. Each item in this array is a new name for a speaker.
  const [newSpeakerNames, setNewSpeakerNames] = useState(undefined);

  // Whether the page is still loading (either the call or the speakers).
  const [loading, setLoading] = useState(true);

  // Whether the call is still transcribing on AWS.
  const [stillTranscribing, setStillTranscribing] = useState(false);

  // Whether the call has failed and cannot be displayed.
  const [callFailed, setCallFailed] = useState(false);

  // The reason for failure if callFailed is true.
  const [failureReason, setFailureReason] = useState(undefined);

  // Snackbar states for errors, warnings, or info (success) messages.
  const [ErrorSnackbar, setErrorSnackbar] = useState(false);
  const [WarnSnackbar, setWarnSnackbar] = useState(false);
  const [InfoSnackbar, setInfoSnackbar] = useState(false);

  // Whether to show redacted content or not.
  const [showRedacted, setShowRedacted] = useState(false);

  // Inline edit state for the call name
  const [editingCallName, setEditingCallName] = useState(false);
  const [tempCallName, setTempCallName] = useState("");

  // History (for redirects) and user context
  const history = useHistory();
  const user = useContext(UserContext);

  /**
   * ------------------------------------------------------------------
   * setCall
   * ------------------------------------------------------------------
   * A helper to set the current call object. This also triggers the
   * speaker logic in a subsequent useEffect.
   *
   * @param {Object} call - The call object retrieved from AWS.
   */
  const setCall = async (call) => {
    setCurrentCall(call);
    // The next useEffect will handle the speakers once currentCall is defined.
  };

  /**
   * ------------------------------------------------------------------
   * getCallFromAWS
   * ------------------------------------------------------------------
   * Fetches the call from AWS, checks its status (COMPLETE, IN_PROGRESS, FAILED).
   * Sets up a subscription if the call is still transcribing.
   */
  useEffect(() => {
    let callSubscription;

    const getCallFromAWS = async () => {
      try {
        const callID = match.params.id;
        const call = await getCall(callID);

        if (typeof call === "string") {
          // If it's a string, presumably it's an error message
          console.error(`Failed to receive call due to error: ${call}`);
          history.push("/unauthorized");
          return;
        }

        // Check the call's status
        switch (call.status) {
          case COMPLETE:
            setCall(call);
            break;
          case IN_PROGRESS:
            setStillTranscribing(true);
            callSubscription = subscribeUpdateCall(
              call.project.id,
              callID,
              setCall
            );
            break;
          default:
            // If status is neither COMPLETE nor IN_PROGRESS, assume call has failed
            setCallFailed(true);
            setFailureReason(call.errorMessage || TIMEOUT);
            setLoading(false);
            setStillTranscribing(false);
            setCurrentCall(call);
        }
      } catch (error) {
        console.error("Error fetching call:", error);
      }
    };

    if (user) {
      getCallFromAWS();
    }

    // Cleanup the subscription on unmount
    return () => {
      if (callSubscription) {
        callSubscription.unsubscribe();
      }
    };
  }, [history, match.params.id, user]);

  /**
   * ------------------------------------------------------------------
   * Speaker Extraction
   * ------------------------------------------------------------------
   * Once the currentCall is set, parse the transcription to extract
   * all unique speaker IDs. Then create an array to hold new speaker names.
   */
  useEffect(() => {
    if (currentCall && currentCall.transcription && !callFailed) {
      // Create an array of unique speaker names from the transcription
      const uniqueSpeakers = [
        ...new Set(currentCall.transcription.map(({ speaker }) => speaker)),
      ];

      setSpeakers(uniqueSpeakers);
      setNewSpeakerNames(new Array(uniqueSpeakers.length).fill(""));

      // Done loading
      setLoading(false);
      setStillTranscribing(false);
    }
  }, [currentCall, callFailed]);

  /**
   * ------------------------------------------------------------------
   * updateSpeakers
   * ------------------------------------------------------------------
   * Replaces the speaker names in the transcription with new ones
   * specified by the user. Calls updateTranscription to persist changes.
   */
  const updateSpeakers = () => {
    const speakerMap = createSpeakerMap();
    let updatedTranscript = currentCall.transcription.map((line) => ({
      ...line,
      speaker: speakerMap[line.speaker] || line.speaker,
    }));

    setCurrentCall({
      ...currentCall,
      transcription: updatedTranscript,
    });
    // Send updated transcription to the backend
    updateTranscription(match.params.id, JSON.stringify(updatedTranscript));
  };

  /**
   * ------------------------------------------------------------------
   * createSpeakerMap
   * ------------------------------------------------------------------
   * Builds a map of old speaker names to new speaker names,
   * ensuring no duplicates. Warns the user if a name is reused.
   *
   * @returns {Object} A map of oldSpeakerName -> newSpeakerName
   */
  const createSpeakerMap = () => {
    let speakerMap = {};
    let usedNames = new Set(speakers);

    for (const index in speakers) {
      const trimmedNewSpeakerName = newSpeakerNames[index].trim();

      if (trimmedNewSpeakerName.length > 0) {
        if (!usedNames.has(trimmedNewSpeakerName)) {
          speakerMap[speakers[index]] = trimmedNewSpeakerName;
          usedNames.add(trimmedNewSpeakerName);
        } else {
          // If user tries to reuse a speaker name, show a warning
          setWarnSnackbar(
            `Please do not use a name already in use: ${trimmedNewSpeakerName}`
          );
          speakerMap[speakers[index]] = speakers[index];
        }
      } else {
        // If user didn't provide a new name, keep the old name
        speakerMap[speakers[index]] = speakers[index];
      }
    }
    return speakerMap;
  };

  /**
   * ------------------------------------------------------------------
   * getEvidence
   * ------------------------------------------------------------------
   * Retrieves the evidence/key terms found in the call.
   *
   * @returns {JSX.Element} Either the key terms or a fallback message.
   */
  const getEvidence = () => {
    if (
      currentCall &&
      currentCall.contains &&
      currentCall.contains.length > 0
    ) {
      return (
        <b>
          {getKeyTermsOnlyJSX(currentCall.contains, KEY_TERMS_BOLD_DELIMINATOR)}
        </b>
      );
    }
    return "No key terms searched/found on audio yet.";
  };

  /**
   * ------------------------------------------------------------------
   * handleSaveCallName
   * ------------------------------------------------------------------
   * Allows the user to rename the call (audioFileName).
   * If the new name is valid, calls Amplify to update the call.
   */
  const handleSaveCallName = async () => {
    if (!tempCallName.trim()) {
      setErrorSnackbar("Call name cannot be empty.");
      return;
    }
    try {
      // Prepare the input for updateCall mutation
      const input = { id: currentCall.id, audioFileName: tempCallName };
      // Make the GraphQL call
      const response = await API.graphql({
        query: updateCall,
        variables: { input },
      });

      // The updated call is response.data.updateCall
      const updatedCall = response.data.updateCall;
      // Update the local state to reflect the new call name
      setCurrentCall({
        ...currentCall,
        audioFileName: updatedCall.audioFileName,
      });
      setEditingCallName(false);
      setInfoSnackbar("Call name updated successfully");
    } catch (error) {
      console.error("Error updating call name:", error);
      setErrorSnackbar("Unable to save call name. Please try again.");
    }
  };

  /**
   * ------------------------------------------------------------------
   * Rendering
   * ------------------------------------------------------------------
   */
  return (
    <Wrapper>
      {/* SEO metadata */}
      <SEO
        title="Transcript | WireTap"
        description="Look at a transcript for your call, rename it, adjust speakers, and download the result."
      />

      {/* MainJumbotron with a Title and optional metrics.
          Here, we inline-edit the call name similarly to how you did CasePage. */}
      <MainJumbotron
        title={
          editingCallName ? (
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              {/* Use the same StyledTextField as in CasePage */}
              <StyledTextField
                value={tempCallName}
                onChange={(e) => setTempCallName(e.target.value)}
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 50 }} // or 100 if you prefer
                style={{ width: "300px" }}
              />
              <StyledButton
                onClick={handleSaveCallName}
                color={"white"}
                style={{ background: "linear-gradient(#209BCF, #26B7F5)" }}
              >
                Save
              </StyledButton>
              <StyledButton
                onClick={() => {
                  setEditingCallName(false);
                  setTempCallName(currentCall.audioFileName);
                }}
                color={"#209BCF"}
                style={{
                  background: "linear-gradient(#FFFFFF, #E9E9E9)",
                }}
              >
                Cancel
              </StyledButton>
            </div>
          ) : (
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              {currentCall ? currentCall.audioFileName : ""}
              <Tooltip title="Edit Call Name" arrow>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (currentCall) {
                      setTempCallName(currentCall.audioFileName);
                      setEditingCallName(true);
                    }
                  }}
                  style={{ color: "white", marginLeft: "8px" }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          )
        }
        metrics={[
          { header: "Date", body: currentCall ? currentCall.date : "" },
          { header: "Time", body: currentCall ? currentCall.time : "" },
          {
            header: "Duration",
            body: currentCall ? currentCall.durationString : "",
          },
        ]}
        breadcrumb={[
          { name: "My Cases", link: `/home` },
          {
            name: currentCall ? currentCall.project.title : "",
            link: currentCall ? `/case/${currentCall.project.id}` : "",
          },
          { name: currentCall ? currentCall.audioFileName : "" },
        ]}
      />

      {/* If not loading, and the call is valid, and the call didn't fail... */}
      {!loading && currentCall && speakers && !callFailed && (
        <BodyWrapper>
          {/* Left side - the transcript view */}
          <Transcript
            currentCall={currentCall}
            setCurrentCall={setCurrentCall}
            showRedacted={showRedacted}
            setShowRedacted={setShowRedacted}
          />

          {/* Right side - Speaker List, Key Terms, Downloads */}
          <RightSideWrapper>
            <ContentWrapper>
              <BorderedBox>
                <BoxHeading>Key Terms</BoxHeading>
                <EvidenceLine>{getEvidence()}</EvidenceLine>
              </BorderedBox>

              <BorderedBox>
                <BoxHeading>Speakers</BoxHeading>
                <SpeakerList
                  speakers={speakers}
                  newSpeakerNames={newSpeakerNames}
                  setNewSpeakerNames={setNewSpeakerNames}
                />
                <StyledButton
                  color={"#209BCF"}
                  style={{
                    background: "linear-gradient(#FFFFFF, #E9E9E9)",
                    alignSelf: "flex-end",
                    marginTop: "10px",
                  }}
                  size="small"
                  onClick={updateSpeakers}
                >
                  Update Speakers
                </StyledButton>
              </BorderedBox>

              <ButtonsWrapper>
                <StyledButton
                  color={"white"}
                  style={{
                    background: "linear-gradient(#209BCF, #26B7F5)",
                    marginBottom: "15px",
                  }}
                  onClick={() => WordTranscription(currentCall, showRedacted)}
                >
                  {showRedacted ? "Download Redacted Word" : "Download Word"}
                </StyledButton>

                {currentCall.audioFileName && (
                  <PDFDownloadButton
                    currentCall={currentCall}
                    showRedacted={showRedacted}
                  />
                )}
                {!currentCall.audioFileName && (
                  <StyledButton
                    color={"#209BCF"}
                    style={{
                      background: "linear-gradient(#FFFFFF, #E9E9E9)",
                    }}
                    disabled={true}
                  >
                    Loading Doc
                  </StyledButton>
                )}
              </ButtonsWrapper>
            </ContentWrapper>
          </RightSideWrapper>
        </BodyWrapper>
      )}

      {/* If the call failed for some reason, show an error message */}
      {!loading && callFailed && failureReason && (
        <FailedWrapper>
          <h1>Call Failed</h1>
          <InfoText>
            <div>{failureReason}</div>
            <div>
              To return home, click <StyledLink to="/home">here</StyledLink>
            </div>
          </InfoText>
        </FailedWrapper>
      )}

      {/* If we're still loading (or still transcribing), show a loading screen */}
      {loading && (
        <LoadingScreen
          loading={loading}
          stillTranscribing={stillTranscribing}
        />
      )}

      {/* Warning and Error Snackbars, plus an optional info snackbar */}
      <WarningErrorSnackbars
        errorMessage={ErrorSnackbar}
        setErrorMessage={setErrorSnackbar}
        warningMessage={WarnSnackbar}
        setWarningMessage={setWarnSnackbar}
        infoMessage={InfoSnackbar}
        setInfoMessage={setInfoSnackbar}
      />
    </Wrapper>
  );
};

export default CallPage;
