// RenderCellExpand.jsx

import React, { useState } from "react";
import styled from "styled-components";
import Popover from "@mui/material/Popover";
import { KEY_TERMS_BOLD_DELIMINATOR } from "../utils/constants.js";
import {
  getFinalStringJSX,
  getKeyTermsOnlyJSX,
} from "../utils/helper functions/keyterms_contains_helper.js";

const Wrapper = styled.div`
  width: ${(props) => props.maxwidth}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    max-width: 459px;
  }
`;

const PopoverInfoWrapper = styled.div`
  padding: 10px;
  border-radius: 25px;
`;

const RenderCellExpand = ({ value, width }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // 1) For the cell display (inline), build an array of React elements:
  //    - If the item has the bold delimiter, use getKeyTermsOnlyJSX
  //    - Otherwise show it as normal text.
  const inlineElements = value.map((item, idx) => {
    if (item.includes(KEY_TERMS_BOLD_DELIMINATOR)) {
      return (
        <React.Fragment key={idx}>
          <b>
            {getKeyTermsOnlyJSX([item], KEY_TERMS_BOLD_DELIMINATOR)}
            {idx < value.length - 1 ? ", " : ""}
          </b>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={idx}>
          {item}
          {idx < value.length - 1 ? ", " : ""}
        </React.Fragment>
      );
    }
  });

  // 2) For the popover (full text), we can display everything with getFinalStringJSX
  //    but that only bolds items with the delimiter. Non-delimited tags will remain plain.
  //    If you prefer simpler text in the popover, just do value.join(", ") or similar.
  const filteredList = value.filter((element) =>
    element.includes(KEY_TERMS_BOLD_DELIMINATOR)
  );
  const popoverContent = getFinalStringJSX(
    filteredList,
    KEY_TERMS_BOLD_DELIMINATOR
  );

  return (
    <Wrapper
      maxwidth={width}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {inlineElements}
      {popoverContent.length > 0 && (
        <StyledPopover
          maxwidth={width}
          anchorEl={anchorEl}
          id="mouse-over-popover"
          sx={{ pointerEvents: "none" }}
          open={open}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <PopoverInfoWrapper>{popoverContent}</PopoverInfoWrapper>
        </StyledPopover>
      )}
    </Wrapper>
  );
};

export default RenderCellExpand;
