import { Auth, Storage } from "aws-amplify";
import { doesUserExist, getUsersProjectsAndCalls } from "../queries.js";
import {
  createUser,
  createProject,
  createCall,
  // deleteCalls,
  // deleteUser,
  // deleteProjects,
} from "../mutations.js";
import { refreshAlert } from "./Alerts.js";
import { filesUnderLimits } from "./files_data_helpers.js";
import { v4 as uuidv4 } from "uuid";
import ReactGA from "react-ga4";

/**
 * Creates a case and adds calls to it
 * @param {string} caseName Name of case
 * @param {String} caseType What type of case it is
 * @param {[files]} files list of files to be uploaded to the case
 * @param {function} setUploadingCallsPercent function to set state of the percent of calls uploaded
 * @param {function} setUploadingCallsMessage function to set state of the uploading call message
 * @param {*} history history from react-router-dom
 * @returns string if fails, otherwise nothing
 */
export const createCase = async (
  caseName,
  caseType,
  files,
  setUploadingCallsPercent,
  setUploadingCallsMessage,
  history
) => {
  const user = await checkCreateUser(); // Get user info
  const username = user.username;
  const uploadableFiles = filesUnderLimits(files); // Get files eligible for upload
  if (uploadableFiles.length === 0) {
    refreshAlert("Upload calls", "No files under size and duration limits.");
    return "No files under size and duration limits."; //TODO: Handle this with a proper error instead of a refresh error
  }

  // Creates a new Case
  let caseID = undefined;
  try {
    ReactGA.event({
      category: "Transcription",
      action: "Created Case",
      label: username, // optional
      value: 1, // optional, must be a number
      nonInteraction: false, // optional, true/false
    });
    caseID = await createProject(
      username,
      caseName,
      caseType,
      Math.floor(Date.now() / 1000)
    );
  } catch (err) {
    refreshAlert("create new case", err);
  }

  //Creates a new audio file call
  await AddCallsToCase(
    caseID,
    files,
    setUploadingCallsPercent,
    setUploadingCallsMessage,
    history,
    "/home"
  );
};

/**
 * Adds calls to existing case
 * @param {string} caseID ID of case to add calls to
 * @param {files} files Files to be uploaded to case
 * @param {function} setUploadingCallsPercent Function that sets calls uploading progress
 * @param {function} setUploadingCallsMessage Function that sets uploading message
 * @param {history} history history for switching pages
 * @param {string} returnRoute route to return to
 */
export const AddCallsToCase = async (
  caseID,
  files,
  setUploadingCallsPercent,
  setUploadingCallsMessage,
  history,
  returnRoute
) => {
  const uploadableFiles = filesUnderLimits(files); // Get files eligible for upload
  if (uploadableFiles.length === 0) {
    refreshAlert("Upload calls", "No files under size and duration limits.");
    return "No files under size and duration limits."; //TODO: Handle this with a proper error instead of a refresh error
  }

  const UploadFilePercent = 100 / uploadableFiles.length;
  setUploadingCallsPercent(0);
  setUploadingCallsMessage("Uploading file");
  ReactGA.event({
    category: "Transcription",
    action: "Added Calls to Case",
    label: caseID, // optional
    value: uploadableFiles.length, // optional, must be a number
    nonInteraction: false, // optional, true/false
  });

  //Creates a new audio file call
  for (const [index, file] of uploadableFiles.entries()) {
    setUploadingCallsMessage(`Uploading file ${file.name}`);
    let storageCallID = undefined;
    const fileType = file.name.substring(file.name.lastIndexOf("."));

    //Check if it should run with speakers
    let fileName = file.name;

    //Create new call/audio file in database
    try {
      const callID = uuidv4();
      storageCallID = `${callID}${fileType}`;

      await createCall(
        callID,
        caseID,
        file.name.replace(/\//g, "_"),
        file.date,
        file.duration
      );
    } catch (err) {
      refreshAlert("create new call", err);
      return; // Do not try to finish rest of function
    }

    // Put call into storage. Always put in convert to get channels info.
    try {
      await Storage.put(`convert/${storageCallID}`, file);
    } catch (err) {
      refreshAlert("upload call to storage", err);
    }
    setUploadingCallsPercent(Math.floor((index + 1) * UploadFilePercent));
  }

  setUploadingCallsPercent(undefined);
  history.push(returnRoute);
};

/**
 * Checks if a user exists in the database. If not, creates a new user.
 * @returns user object
 */
export const checkCreateUser = async () => {
  let user = undefined;
  try {
    user = await Auth.currentAuthenticatedUser();
    const userExists = await doesUserExist(user.username);
    if (!userExists) {
      //Create user if not in database yet.
      await createUser(user.username);
    }
    return user;
  } catch (err) {
    refreshAlert("authenticate user", err);
  }
};

/**
 * Gets the total duration of all calls in all project for user
 * @returns total duration of all calls in all projects for user
 */
export const getTotalDuration = async () => {
  let totalDuration = 0;
  try {
    const user = await Auth.currentAuthenticatedUser();
    const username = user.username;
    const projectsAndCalls = await getUsersProjectsAndCalls(username, false);
    projectsAndCalls.forEach((project) => {
      totalDuration += project.totalDuration;
    });
  } catch (error) {
    console.error(error.message);
  }
  return totalDuration;
};

/**
 * Deletes everything in the database for this user
 */
export const deleteDatabaseForUser = async () => {
  // const user = await Auth.currentAuthenticatedUser();
  // //TODO: Update the below to get list of projects and calls better that can handle pagination
  // // const projectsAndCalls = await getUsersProjectsAndCalls(user.username, true);
  // let callIDs = [];
  // const projectIDs = projectsAndCalls.map((item) => {
  //   for (const call of item.calls) {
  //     callIDs.push(call.id);
  //   }
  //   return item.id;
  // });
  // await deleteUser(user.username);
  // await deleteProjects(projectIDs);
  // await deleteCalls(callIDs);
  // console.log("database deleted for user");
};
