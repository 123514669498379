import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// MUI Components
import TextField from "@mui/material/TextField";
import Backdrop from "@mui/material/Backdrop";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

// Components
import StyledButton from "./StyledButton.jsx";
import TemplateModal from "./TemplateModal.jsx";

// Utils
import { unixToLocal } from "../utils/time.js";
import { MAX_FILE_SIZE_MB } from "../utils/constants.js";
import {
  getFileSizeInMB,
  secsToString,
} from "../utils/helper functions/files_data_helpers.js";

const InputWrapper = styled.div`
  width: auto;
  margin-right: 30px;
`;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-fullWidth {
    margin-top: 20px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.primaryLight};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.primaryLight};
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.theme.primaryLight};
  }
`;

const SideWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.div`
  align-self: center;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 15px;
`;

const ListWrapper = styled.div`
  min-height: 430px;
  height: 55vh;
  overflow: auto;
`;

const BottomWrapper = styled.div`
  display: flex;
  margin: 20px 14% 20px;
  justify-content: flex-end;
  align-items: center;
`;

const TimeAvailableString = styled.div`
  font-style: italic;
  margin-right: 15px;
  color: ${(props) => (props.color ? props.color : "")};
`;

const ModalWrapper = styled.div`
  width: 75vw;
  height: 50vh;
  background-color: white;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-sizing: border-box;
  justify-content: space-between;
  z-index: 1401;

  .MuiLinearProgress-root {
    border-radius: 10px;
    height: 8px;
    flex-grow: 1;
  }

  .MuiLinearProgress-bar1Determinate {
    border-radius: 8px;
    background-color: ${(props) => props.theme.secondaryLight};
  }
`;

const ModalHeader = styled.h2`
  margin: 0px;
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressBarAndPercent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const DisplayPercent = styled.div`
  margin-left: 15px;
  font-size: 1.1rem;
  font-weight: 550;
`;

const ProgressMessage = styled.div``;

const DoNotLeaveMessage = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 350;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.linkLightBlue};
  text-decoration: underline;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: 1400;
`;

/**
 * JSX of input for case name and case type
 * @param {object} props state for case Name and case Type.
 * @returns JSX of input for case name and case type
 */
export const CaseInfoTextFields = ({
  caseNameVal,
  setCaseNameVal,
  caseTypeVal,
  setCaseTypeVal,
}) => {
  return (
    <InputWrapper>
      <StyledTextField
        required
        value={caseNameVal}
        onChange={(e) => setCaseNameVal(e.target.value)}
        label="Case Name"
        variant="outlined"
        inputProps={{ maxLength: 50 }}
        fullWidth
      />
      <StyledTextField
        required
        value={caseTypeVal}
        onChange={(e) => setCaseTypeVal(e.target.value)}
        label="Case type"
        variant="outlined"
        inputProps={{ maxLength: 100 }}
        fullWidth
      />
    </InputWrapper>
  );
};

/**
 * Deletes a file from the audio list
 * @param {int} index index of the file to be deleted
 * @param {[files]} files list of files
 * @param {object} setFiles function to set files
 */
const deleteCall = (index, files, setFiles) => {
  const newFiles = [...files];
  newFiles.splice(index, 1);
  setFiles(newFiles);
};

/**
 * Checks if this file is from GTL and updates the file if so.
 * @param {File Object} file file of user upload
 * @returns timeString of what to display in UI or undefined if not from this provider
 */
const gtlFile = (file) => {
  try {
    // GTL
    let unixTime = file.name.split("_")[0];
    const unixTimeInt = parseInt(unixTime);
    unixTime = unixTimeInt.toString();
    if (unixTime.length !== 9 && unixTime.length !== 10) {
      throw new Error("Could not get time from name for GTL");
    }
    if (new Date(unixTime * 1000).getTime()) {
      file.date = unixTimeInt;
      return "Call Date: " + unixToLocal(unixTimeInt);
    }
    throw new Error("Could not get time from name for GTL");
  } catch {
    return undefined;
  }
};

/**
 * Checks if this file is from CPC and updates the file if so.
 * @param {File Object} file file of user upload
 * @returns timeString of what to display in UI or undefined if not from this provider
 */
const cpcFile = (file) => {
  try {
    // CPC
    let year = file.name.split("_")[3];
    let month = file.name.split("_")[4];
    let day = file.name.split("_")[5];
    let date = `${year}-${month}-${day}`;
    if (new Date(date).getTime()) {
      file.date = Math.floor(new Date(date).getTime() / 1000);
      return `Call Date: ${month}-${day}-${year}`;
    }
    throw new Error("Could not get time from name for CPC");
  } catch {
    return undefined;
  }
};

/**
 * Sets the file date if provider is unknown
 * @param {File Object} file file of user upload
 * @returns timeString of what to display in UI or undefined if not from this provider
 */
const setDefaultFile = (file) => {
  let date = Math.round(Date.now() / 1000);
  if (file.lastModified) {
    date = file.lastModified / 1000;
  }
  file.date = parseInt(date);
  console.warn(
    `Reverting to date last modified for file name: ${file.name}. Date last modified: ${file.date}`
  );
  return "Last Modified: " + unixToLocal(date); // Go from miliseconds to seconds
};

/**
 * Gets the timeString to display in UI and sets the date on the file.
 * @param {File Object} file file of user upload
 * @returns timeString of what to display in UI or undefined if not from this provider
 */
const getTimeFromName = (file) => {
  let timeString = gtlFile(file);
  if (timeString === undefined) {
    timeString = cpcFile(file);
  }
  if (timeString === undefined) {
    timeString = setDefaultFile(file);
  }
  return timeString;
};

/**
 * Creates a list of calls in jsx based on the calls inputted by user
 * @returns JSX of the calls
 */
const createList = (files, setFiles) => {
  const listItems = files.map((file, index) => {
    let timeString = undefined;
    const size = getFileSizeInMB(file.size);
    let sizeString = ` — Size: ${size}MB`;
    //TODO: Replace size with time.
    // if (file.duration) {
    //   sizeString = `- Duration: ${Math.round(file.duration)} secs`;
    // }
    timeString = getTimeFromName(file);

    return (
      <ListItem key={file.name}>
        {" "}
        <ListItemText
          primary={file.name}
          secondary={
            <span>
              {timeString}{" "}
              <span style={{ color: size > MAX_FILE_SIZE_MB ? "red" : "" }}>
                {sizeString}
              </span>
            </span>
          }
        />
        <ListItemSecondaryAction
          children={
            <div>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => deleteCall(index, files, setFiles)}
                name={file.name}
              >
                <DeleteIcon name={file.name} />
              </IconButton>
            </div>
          }
        />
      </ListItem>
    );
  });
  return listItems;
};

/**
 * JSX for right side of the page
 * @param {object} props files and setFiles function
 * @returns JSX for right side of page
 */
export const RightSideCallList = ({ files, setFiles }) => {
  return (
    <SideWrapper>
      <HeaderText>Audio Files Added</HeaderText>
      <ListWrapper>
        <List component="nav">{createList(files, setFiles)}</List>
      </ListWrapper>
    </SideWrapper>
  );
};

/**
 * JSX for the bottom of the page
 * @param {object} props uploading call percent and timeAvailableAfterTranscription and numCallsLoaded
 * @returns JSX for the bottom of the page
 */
export const BottomButtons = ({
  uploadingCallsPercent,
  timeAvailableAfterTranscription,
  numCallsLoaded,
}) => {
  return (
    <BottomWrapper>
      {timeAvailableAfterTranscription === undefined ||
      timeAvailableAfterTranscription > 0 ? (
        <TimeAvailableString>
          {numCallsLoaded > 0
            ? "Time remaining after transcription: "
            : "Time available for transcription: "}
          {secsToString(timeAvailableAfterTranscription, false, true)}
        </TimeAvailableString>
      ) : (
        <TimeAvailableString color="red">
          {"Time needed to purchase: "}
          {timeAvailableAfterTranscription > -120 &&
          timeAvailableAfterTranscription < 0
            ? " (2 min minimum)"
            : ""}

          {secsToString(
            Math.abs(Math.min(timeAvailableAfterTranscription, -120)),
            false,
            true
          )}
        </TimeAvailableString>
      )}
      {timeAvailableAfterTranscription >= 0 ? (
        <StyledButton
          type="submit"
          color={"white"}
          style={{
            background: "linear-gradient(#209BCF, #26B7F5)",
            border: "none",
          }}
          disabled={uploadingCallsPercent !== undefined}
        >
          Transcribe
        </StyledButton>
      ) : (
        <StyledButton
          type="submit"
          color={"white"}
          style={{
            background: "linear-gradient(#209BCF, #26B7F5)",
            border: "none",
          }}
          disabled={uploadingCallsPercent !== undefined}
        >
          Purchase
        </StyledButton>
      )}
    </BottomWrapper>
  );
};

export const ModalView = ({ uploadingCallsPercent, uploadingCallsMessage }) => {
  return (
    <StyledBackdrop open={uploadingCallsPercent !== undefined}>
      <ModalWrapper>
        <ModalHeader>Uploading Calls to WireTap</ModalHeader>
        <ProgressWrapper>
          <ProgressBarAndPercent>
            <LinearProgress
              variant="determinate"
              value={uploadingCallsPercent || 0}
            />
            <DisplayPercent>{uploadingCallsPercent}%</DisplayPercent>
          </ProgressBarAndPercent>
          <ProgressMessage>{uploadingCallsMessage}</ProgressMessage>
        </ProgressWrapper>
        <DoNotLeaveMessage>
          Please do not close or refresh this page while uploading calls.
        </DoNotLeaveMessage>
      </ModalWrapper>
    </StyledBackdrop>
  );
};

export const IntroTutorial = ({ showIntroTutorial, setShowIntroTutorial }) => {
  const bodyElements = (
    <div style={{ marginTop: "20px" }}>
      Welcome to WireTap! If this is your first time using WireTap, it's
      important to review our short{" "}
      <StyledLink
        to="/helpcenter/userguide"
        target="_blank"
        rel="noopener noreferrer"
      >
        user guide
      </StyledLink>{" "}
      to know what files you can transcribe and to learn how to "convert" your
      jail calls to standard audio when you want to use them with WireTap.
      <StyledLink
        to="/helpcenter/userguide#19"
        target="_blank"
        rel="noopener noreferrer"
      >
        [Hint: when downloading them, hit the "convert" button before
        downloading.]
      </StyledLink>
    </div>
  );
  return (
    <TemplateModal
      open={showIntroTutorial}
      setOpen={setShowIntroTutorial}
      header="Welcome to WireTap!"
      subHeader="Check out our User Guide"
      bodyElements={bodyElements}
      size="small"
      closeButton="Got it"
    />
  );
};
